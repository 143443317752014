@use 'sass:map';

@import 'assets/mixins';
@import 'assets/grid';

.WebformStepWrapper {
  z-index: 100;
  position: relative;

  .Button {
    font-weight: normal;
  }

  &.has-value {
    .WebformStepWrapper__title__content {
      color: $color--steel-blue;
    }
  }

  &:hover {
    .Accordion__item__title .icon--arrow-right {
      background-position-y: 0em;
      opacity: 1;
    }

    .WebformStepWrapper__title__content {
      color: $color--black;
    }

    .WebformStepWrapper__title__icon .icon {
      background-position-y: 0em;
    }
  }

  &__title {
    &:hover {
      background: $color--kournikova;

      .WebformStepWrapper__title__value {
        display: none;
      }
      .WebformStepWrapper__title__edit_placeholder {
        display: block;
      }
    }

    &__icon, &__content {
      display: inline-block;
    }
    &__value, &__edit_placeholder {
      position: absolute;
      right: 4.5rem;
      line-height: 5rem;
      top: 0;
      @include font--light();

      @media #{$normal} {
        right: 6rem;
        line-height: 7rem;
      }
    }

    &__edit_placeholder {
      display: none;
    }

    &__icon {
      font-size: 2rem;
      margin-right: 1rem;
    }

    &__content {
      text-transform: uppercase;
      color: $color--fuscous-gray;
    }
  }

  &__content {
    padding: map.get($spacers, 2);
    padding-top: 0;

    @media #{$normal} {
      padding: map.get($spacers, 3);
      padding-top: 0;
    }

    &__body {
      margin-bottom: 2rem;
      @media #{$normal} {
        margin-bottom: 3rem;
      }
      p {
        margin-bottom: 0;
      }
    }

    &__title {
      margin-bottom: 1rem;
    }

    &__action__error  {
      font-size: 1.4rem;
      color: $color--error;
      margin-left: 2rem;
      display: none;

      @media #{$normal} {
        display: inline-block;
      }

      &.mobile {
        display: block;
        margin-left: 0;
        margin-top: 2rem;

        @media #{$tablet} {
          display: none;
        }
      }
    }

    &__action__source {
      float: right;
      line-height: 4rem;
      font-size: 1.2rem;
      font-style: italic;
    }

    &__form_element {

      .FormElement {
        margin-bottom: 2rem;

        > label {
          color: $color--calypso;
          text-transform: uppercase;
          font-size: 1.2rem;
          line-height: 1.6rem;
          font-weight: 100;

          label {
            @include font--light();
          }
        }
        label span {
          font-size: 1.4rem;
          line-height: 2.2rem;
          font-weight: 100;
        }
      }

      .FormElement__inner {
        flex-wrap: wrap;
      }

      .RadioButtons__inner {
        flex-direction: row;

      }
      .WebformRadios__radio__input {
        display: flex;
        @include font--medium();
        background: $color--black-squeeze-lighter;
        padding: 1rem;
        margin-right: 1rem;
        margin-bottom: 1rem;
        height: 4.4rem;

        &:active, &:focus, &:hover {
          background: $color--black-squeeze-lighter;

          span {
            color: $color--steel-blue;
          }

          &:before {
            background-position-y: -1em;
            background-position-x: -25em;
          }
        }

        &::before {
          align-self: center;
          margin-right: 0.5rem;
        }

        &.icon-variant-1.icon-before {
          background: $color--black-squeeze;
          span {
            color: $color--steel-blue;
          }

          &:before {
            background-position-y: -1em;
            background-position-x: -24em;
          }

        }

        input {
          margin-right: 0.5rem;
          display: none;
        }

      }
    }
  }

  .Accordion__item {
    background: $color--white;
    margin-bottom: 1rem;

    &:not(.collapsed) {
      box-shadow: inset 0px 0px 0px 2px $color--kournikova;
    }

    .WebformStepWrapper__title {
      padding: 1.5rem;

      @media #{$normal} {
        padding: map.get($spacers, 3);
      }
    }

    .Accordion__item__title button {
      font-size: 1.2rem;
      line-height: 2rem;
    }

    &__content {
      padding-top: 1rem;
    }

    .icon {
      display: inline-block;
      pointer-events: none;
    }

    .icon--arrow-right {
      position: absolute;
      right: 1.5rem;
      top: 1.5rem;
      background-position-x: -4em;
      transform: rotate(270deg);
      font-size: 2rem;
      background-position-y: -1em;
      opacity: 0.5;

      @media #{$normal} {
        right: 2.5rem;
        top: 2.5rem;
      }
    }

    &.collapsed {
      .icon--arrow-right {
        transform: rotate(90deg);
      }
    }
  }
}
