@use 'sass:map';

@import 'assets/variables';
@import 'assets/mixins';

.ParagraphWebform {
  margin-bottom: 3rem;

  @media #{$normal} {
    margin-bottom: 12rem;
  }

  &__results {
    z-index: 100;
    position: relative;

    &__body {
      margin-bottom: 4rem;
      @include font--medium();

      &__no_additional_message {
        margin-top: 2rem;
        @include body--regular();
      }

      p {
        margin-bottom: 2rem;
        font-weight: 500;
      }

      .Button {
        border: none;
      }
    }
  }

  &#webform_rekentool {

    .container {
      @media #{$tablet-down} {
        padding: 0;
      }
    }

    .not-applicable .ParagraphWebform__results__body p{
      @media #{$tablet-down} {
        padding-right: 6rem;
      }
    }

    strong {
      @include body--bold();
      font-weight: normal;
    }

    .Accordion {
      z-index: 100;
    }
    .ParagraphWebform__wrapper {
      background: $color--yelow;
      position: relative;
      padding: 4rem 2rem 4rem 2rem;

      @media #{$tablet} {
        padding: map.get($spacers, 8);
      }

      &__image {
        width: 14rem;
        height: 14.7rem;
        background-image: url('../../assets/images/calculator.svg');
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 10;

        @media #{$normal} {
          width: 22.8rem;
          height: 24rem;
        }
      }
    }

    .WebformProcessedText {
      margin-bottom: 4rem;

      @media #{$normal} {
        margin-bottom: 5rem;
      }

      h2 {
        margin-bottom: 2rem;
      }
      p{
        margin-bottom: 0;
      }
    }

    .webform-container {
      margin-bottom: 4rem;

      @media #{$normal} {
        margin-bottom: 5rem;
      }

      h4 {
        font-weight: normal;
      }
    }

    h4 {
      text-transform: uppercase;
      margin-bottom: 1.5rem;
      font-weight: normal;
    }

    table {
      width: 100%;

      tr {
        &:not(:first-child) {
          border-top: 1px solid $color--mystic;
        }

        td:first-child span:first-child{
          max-width: 16rem;
          display: inline-block;

          @media #{$tablet} {
            max-width: 23.8rem;
          }
        }

        &:last-child {
          background: $color--kournikova;
          border: none;
          td {
            @include font--secondary();
            font-size: 1.4rem;
            line-height: 16.8px;
            font-weight: normal;
          }
        }
      }

      td {
        @include font--medium();
        padding: 1rem;
        padding-right: 0;
        position: relative;
        font-size: 1.4rem;
        line-height: 16.8px;

        &:first-child {
          span:last-child {
            color: $color--steel-blue;
            right: 0;
            top: 0;
            position: absolute;
            line-height: 5.3rem;

            @media #{$tablet} {
              line-height: 3.6rem;
            }
          }
        }

        &:last-child {
          text-align: right;
          padding-right: 1rem;
          width: 10rem;

          span {
            float: left;
            padding-left: 1rem;
          }
        }
      }
    }

    .export {
      font-size: 1.4rem;
      border: 0;
    }
  }

  .WebformComponent__submit__next {
    display: none;
  }
}
